export const state = () => ({
  ready: false,
  formIds: [],
  formNodes: {},
})

export const mutations = {
  ready(state) {
    // get each form from
    state.formIds.forEach((formId) => {
      // get form node
      const form = document.querySelector(`#msdynamics-form-embed-${formId}`)
      if (!form) return

      // remove style tags from every form
      form.querySelectorAll('style').forEach((style) => {
        style.remove()
      })

      // set form in the vuex `formNodes` state
      state.formNodes[formId] = form
    })
    state.ready = true
  },
  setForms(state, formIds) {
    state.formIds = formIds
  },
}
