import { buildLocaleRoutingMap } from '~/plugins/localeResolver/navigation'
import UrlResolver from '~/plugins/localeResolver/UrlResolver'

export default ({ app, $graphcms, i18n, route }, inject) => {
  /**
   * Global localeResolver inject
   */
  let routingMap = null
  inject('localeResolver', {
    /**
     * Initialize the full routingMap UrlResolver
     */
    async init() {
      if (routingMap) {
        return
      }
      let routingList = null
      try {
        routingList = await buildLocaleRoutingMap($graphcms, i18n)
      } catch (e) {
        console.info('Error on localeResolve init', e)
      }
      routingMap = new UrlResolver(routingList)

      // Redirect to cleaned url on app startup
      routingMap.redirectFixPath(
        app.localePath(route.fullPath),
        app.router,
        route,
        i18n.localeProperties.code
      )
    },

    /**
     * Wrap the nuxt i18n localePath method
     * to rewrite faulty slugs
     */
    localePath: (...args) => {
      if (!routingMap) {
        return app.localePath(...args)
      }
      return routingMap.fixPath(
        app.localePath(...args),
        i18n.localeProperties.code
      )
    },

    /**
     * Wrap the nuxt i18n switchLocalePath method
     * to rewrite faulty slugs
     */
    switchLocalePath: (targetLang) => {
      if (!routingMap) {
        return app.switchLocalePath(targetLang)
      }
      return routingMap.fixPath(app.switchLocalePath(targetLang), targetLang)
    },
  })
}
