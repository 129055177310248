export default {
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
  COPYRIGHT: 'COPYRIGHT',
  BURGERMETA: 'BURGERMETA',
  BURGERMAIN: 'BURGERMAIN',
  SIDEBAR_DASHBOARD: 'SIDEBAR_DASHBOARD',
  SIDEBAR_USERSETTINGS: 'SIDEBAR_USERSETTINGS',
  DASHBOARD: 'DASHBOARD',
  USERLINKS: 'USERLINKS',
}
