/**
 * Helper to rewrite faulty routing URLs
 */
export default class UrlResolver {
  constructor(routingMap) {
    this.routingMap = routingMap
    this.slugLookup = new Map()
  }

  /**
   * Fixes any locale errors in the given url
   * for the specified language code
   */
  fixPath(url, currentLang) {
    if (!this.routingMap) {
      return url
    }
    const translatePath = this.splitIntoSlugs(url).map((slug) => {
      return this.applyMatch(slug, currentLang)
    })
    return '/' + translatePath.join('/')
  }

  /**
   * Applies the fixPath method on the current
   * route path and replaces the current route
   * with the fixed one, if an error was found
   */
  redirectFixPath(targetPath, router, currentRoute, currentLocale) {
    const trueTarget = this.fixPath(targetPath, currentLocale)
    if (
      trueTarget !== targetPath &&
      currentLocale === this.getLocaleFromRoute(currentRoute)
    ) {
      router.replace({
        path: trueTarget,
      })
    }
  }

  /**
   * Resolves the given slug on the full routingMap
   * containing all slugs with their translation
   */
  applyMatch(slug, currentLang) {
    const lookupKey = `${currentLang}-${slug}`
    if (this.slugLookup.has(lookupKey)) {
      return this.slugLookup.get(lookupKey)
    }
    const slugKeys = Object.keys(this.routingMap)
    for (let i = 0; i < slugKeys.length; i++) {
      const slugs = this.routingMap[slugKeys[i]]
      for (let j = 0; j < slugs.length; j++) {
        const currentSlug = slugs[j]
        if (!currentSlug[currentLang]) {
          continue
        }
        const languages = Object.keys(currentSlug)
        for (let k = 0; k < languages.length; k++) {
          if (languages[k] === currentLang) {
            continue
          }
          if (currentSlug[languages[k]] === slug) {
            this.slugLookup.set(lookupKey, currentSlug[currentLang])
            return currentSlug[currentLang]
          }
        }
      }
    }
    this.slugLookup.set(lookupKey, slug)
    return slug
  }

  /**
   * Fetch the locale from the given route name
   */
  getLocaleFromRoute(route) {
    const matches = Array.from(route.name.matchAll('___(.+)'))
    if (matches.length === 0 || matches[0].length < 2) {
      return null
    }
    return matches[0][1]
  }

  /**
   * Splits the given path into all
   * its partial slugs
   */
  splitIntoSlugs(url) {
    return url.split('/').filter((a) => a.length)
  }
}
