export const state = () => ({
  layer: false,
  drawer: null,
  dialog: null,
  dialogProps: null,
  share: null,
  theme: 'red',
  themes: {
    white: {
      color: '#ffffff',
      accent: '#C60C30',
      font: '#000000',
    },
    red: {
      color: '#C60C30',
      accent: '#ffffff',
      font: '#ffffff',
    },
    emerald: {
      color: '#00ae65',
      accent: '#B6BF00',
      font: '#ffffff',
    },
    lime: {
      color: '#B6BF00',
      accent: '#B00057',
      font: '#000000',
    },
    fuchsia: {
      color: '#B00057',
      accent: '#00ae65',
      font: '#ffffff',
    },
  },
  layout: 'page',
})

export const mutations = {
  setLayer(state, payload) {
    state.layer = payload
  },

  setDrawer(state, { drawer, layer }) {
    state.drawer = drawer
    state.layer = layer
  },

  setDialog(state, { dialog, layer, props }) {
    state.dialog = dialog
    state.layer = layer
    state.dialogProps = props || null
  },

  setShare(state, { title, url, layer }) {
    state.share = { title, url }
    state.layer = true
  },

  setTheme(state, payload) {
    state.theme = payload
  },

  setLayout(state, payload) {
    state.layout = payload
  },

  closeShare(state) {
    state.share = null
    if (!state.dialog) {
      state.layer = false
    }
  },
}
