export const state = () => ({
  allMentors: [],
})

export const mutations = {
  setMentors(state, mentors) {
    state.allMentors = mentors
  },
}

export const getters = {
  getMentors(state) {
    return state.allMentors
  },
}
