import { API } from 'aws-amplify'
import forumApi from '~/utils/forumApi'
import usergroup from '~/utils/usergroup'

export default async function ({ $auth, store }) {
  if ($auth && $auth.loggedIn) {
    let forumId = 0
    try {
      const { results } = await API.get('forum', forumApi.MEMBERS, {
        queryStringParameters: {
          email: $auth.user.email,
        },
      })
      if (Array.isArray(results) && results.length === 1) {
        forumId = results[0].id
        store.commit('user/setForumId', forumId)
      }
    } catch (error) {
      console.error('Error at getting forum id ', error)
    }
    API.post('api', '/mentor', {
      body: {
        email: $auth.user.email,
        medelName: $auth.user.name,
        nickname: $auth.user.nickname,
        firstName: $auth.user.given_name,
        lastName: $auth.user.family_name,
        forumId,
      },
    })
    store.commit('user/setGroup', usergroup.USER)
  }
}
