export const state = () => ({
  translations: {},
  defaultTranslations: {},
  worldCountries: [],
  defaultWorldCountries: [],
  currentLocation: null,
})

export const mutations = {
  setTranslations(state, { translations }) {
    const localeKeys = {}
    translations.forEach(function (translation) {
      localeKeys[translation.key] = translation.text
    })
    state.translations = localeKeys
  },

  setDefaultTranslations(state, { translations }) {
    const localeKeys = {}
    translations.forEach(function (translation) {
      localeKeys[translation.key] = translation.text
    })
    state.defaultTranslations = localeKeys
  },

  setWorldCountries(state, countries) {
    state.worldCountries = countries
  },

  setDefaultWorldCountries(state, countries) {
    state.defaultWorldCountries = countries
  },

  setCurrentLocation(state, location) {
    state.currentLocation = location
  },
}

export const getters = {
  getTranslations: (state) => {
    return state.translations
  },
  getWorldCountries: (state) => {
    return state.worldCountries
  },

  getCountryByCountryCode:
    (state) =>
    (countryCode, fromDefaultLang = false) => {
      if (countryCode === null) return null
      const worldCountries = []
      if (fromDefaultLang) {
        worldCountries.push(...state.defaultWorldCountries)
      } else {
        worldCountries.push(...state.worldCountries)
      }
      if (countryCode) {
        const prop = countryCode.length === 3 ? 'isoAlpha3' : 'countryCode'
        return worldCountries.find((country) => {
          return country[prop]?.toLowerCase() === countryCode.toLowerCase()
        })
      } else {
        return null
      }
    },

  getCountriesSelectOptions: (state) => {
    const data = {}
    const countryCodes = []
    const worldCountries = [...state.worldCountries]

    const sortedCountries = worldCountries.sort((a, b) => {
      if (a.countryName < b.countryName) {
        return -1
      }
      if (a.countryName > b.countryName) {
        return 1
      }
      return 0
    })

    worldCountries.forEach((country) => {
      const { continentName, isoAlpha3 } = country
      data[continentName] = []
      countryCodes.push(isoAlpha3)
    })

    sortedCountries.forEach((country) => {
      const { continentName, isoAlpha3, countryName } = country

      data[continentName].push({
        isoAlpha3,
        countryName,
      })
    })

    return { data, countryCodes }
  },

  getCurrentLocation: (state) => {
    return state.currentLocation
  },

  getDefaultNameByLanguageCode: (state) => (code) => {
    if (state.defaultTranslations[code]) {
      return state.defaultTranslations[code].toLowerCase()
    }
    return null
  },
}

export const actions = {
  async fetchWorldCountries({ commit }) {
    // method to fetch the countries for the given lang
    const fetchWorldCountries = async (lang) => {
      let countries = []
      if (process.env.GEONAMES_URL && process.env.GEONAMES_USER) {
        const axios = this.$axios.create({
          transformRequest: [
            (data, headers) => {
              // no authorization for geonames
              delete headers.Authorization
              delete headers.common.Authorization
              return data
            },
          ],
        })
        const api = await axios.$get(process.env.GEONAMES_URL, {
          params: {
            lang,
            username: process.env.GEONAMES_USER,
          },
        })
        countries = api.geonames
      }
      return countries
    }

    // Fetch current lang and default lang
    const localeCountries = await fetchWorldCountries(
      this.app.i18n.localeProperties.languageCode
    )
    let defaultCountries = localeCountries
    if (this.app.i18n.defaultLocale !== this.app.i18n.locale) {
      defaultCountries = await fetchWorldCountries(this.app.i18n.defaultLocale)
    }

    commit('setWorldCountries', localeCountries)
    commit('setDefaultWorldCountries', defaultCountries)
  },
}
