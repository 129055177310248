import { API } from 'aws-amplify'
import forumApi from '~/utils/forumApi'

export default ({ app, store }, inject) => {
  inject('forumApi', {
    getFollowsByMemberId(id = '') {
      const userId = id || store.state.user?.forumId
      return API.get(
        'forum',
        forumApi.MEMBERS + `/${userId}` + forumApi.FOLLOWS
      )
    },
    getFollowingElements(items, type) {
      const elements = []
      items.reverse().forEach(async (item) => {
        if (item.followArea === type) {
          switch (type) {
            case 'event':
              elements.push(
                await API.get(
                  'forum',
                  forumApi.CALENDAR_EVENTS + `/${item?.followId}`
                )
              )
              break
            case 'topic':
              elements.push(
                await API.get(
                  'forum',
                  forumApi.FORUM_TOPICS + `/${item?.followId}`
                )
              )
              break
            default:
              break
          }
        }
      })
      return elements
    },
    getTopicsByUserId(userId) {
      return API.get('forum', forumApi.FORUM_TOPICS, {
        params: { authors: userId },
      })
    },
    getPostsByUserId(userId) {
      return API.get('forum', forumApi.FORUM_POSTS, {
        params: { authors: userId },
      })
    },
  })
}
