import { GraphQLClient } from 'graphql-request'
import {} from 'dotenv/config'

export const saveSubmissionLog = async (data) => {
  const userInput = { log: data }
  const mutation = `
    mutation ($userInput: Json!) {
      createSubmissionLog(data: { jsonData: $userInput }) {
        id
      }
    }
  `
  const variables = {
    userInput: JSON.stringify(userInput),
  }

  const gql = new GraphQLClient(`${process.env.GRAPHCMS_URL}`, {
    headers: {
      Authorization: `Bearer ${process.env.GRAPHCMS_TOKEN}`,
    },
  })

  try {
    const response = await gql.request(mutation, variables)
    return { success: true, response }
  } catch (error) {
    console.error('Error saving the log', error)
    return { success: false, error }
  }
}
