import axios from 'axios'
import { saveSubmissionLog } from './submissionLogs'

const initData = {
  scripts: {
    loader:
      'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007',
    tracker:
      'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007',
  },
  // Main Forms
  main: {
    hostname: process.env.MSD_STEP_HOST,
    wrapper: process.env.MSD_STEP_WRAPPER,
    element: process.env.MSD_STEP_ELEMENT,
    config: process.env.MSD_STEP_CONFIG,
  },
  newsletter: {
    hostname: process.env.MSD_NEWSLETTER_HOST,
    wrapper: process.env.MSD_NEWSLETTER_WRAPPER,
    element: process.env.MSD_NEWSLETTER_ELEMENT,
    config: process.env.MSD_NEWSLETTER_CONFIG,
  },

  // Exclusive inject Forms
  subscriptionCenter: {
    hostname: process.env.MSD_SUBSCRIPTION_HOST,
    wrapper: process.env.MSD_SUBSCRIPTION_WRAPPER,
    element: process.env.MSD_SUBSCRIPTION_ELEMENT,
    config: process.env.MSD_SUBSCRIPTION_CONFIG,
  },
}

const defaultFormList = ['main', 'newsletter']
const excludeFormRoutes = ['subscription-center']

export function getInjectedFormIds(route) {
  for (let i = 0; i < excludeFormRoutes.length; i++) {
    if (route.name.indexOf(excludeFormRoutes[i]) === 0) {
      return []
    }
  }
  return defaultFormList
}

function setInputValue(el, value) {
  el.value = value
}

function setRadioValue(els, value, options, defaultIndex) {
  els.forEach((el) => {
    el.checked = false
  })
  const index = options.indexOf(value)
  if (index >= 0) {
    els[index].checked = true
  } else {
    els[defaultIndex].checked = true
  }
}

function setCheckbox(el, value) {
  el.checked = !!value
}

function setSelectValue(el, value) {
  if (el && el.children && value !== null && value !== undefined) {
    const children = el.children
    for (let i = 0; i < children.length; i++) {
      children[i].selected =
        children[i].textContent.trim().toLowerCase() ===
        value.toString().toLowerCase()
    }
  }
}

async function setCountryValue(el, host, value) {
  const input = el.querySelector('input')
  const fieldId = el.querySelector('label').getAttribute('for')
  const fieldName = el
    .querySelector('.ui-menu-item')
    .getAttribute('data-value')
    .split(',')[0]
  try {
    const countryId = await getCountryId(host, fieldId, fieldName, value)
    input.setAttribute('data-value', `${fieldName},${countryId}`)
  } catch (e) {
    console.error(e)
  }
  input.value = value
}

/**
 * Requests the MSD API to query for a
 * country ID matching the given value
 */
async function getCountryId(host, fieldId, fieldName, value) {
  const result = await axios.post(`https://${host}/t/lookup/${fieldId}`, {
    searchText: value,
    page: 1,
    pagingInfos: [
      {
        entityName: fieldName,
      },
    ],
  })
  if (result.data.ItemsV2.length === 0) {
    throw new Error(`no country found matching the name ${value}`)
  }

  // was necessary to create a indian country check to return the second position instead the first one
  const items = result.data.ItemsV2[0].Items
  const countriesToCheck = ['india']
  if (items.length > 1 && countriesToCheck.includes(value.toLowerCase())) {
    return items[1].id
  }
  return items[0].id
}

/**
 * Auxiliar function save submissionLog async
 */
async function submissionLog(data) {
  return await saveSubmissionLog(data)
}

/**
 * Replacement Map for unknown country names
 */
const countryReplacementMap = {
  'THE GAMBIA': 'GAMBIA',
  'SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS':
    'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  'HEARD AND MCDONALD ISLANDS': 'HEARD ISLAND AND MCDONALD ISLANDS',
  'U.S. VIRGIN ISLANDS': 'VIRGIN ISLANDS, U.S.',
  'BRITISH VIRGIN ISLANDS': 'VIRGIN ISLANDS, BRITISH',
  'ST VINCENT AND GRENADINES': 'SAINT VINCENT AND THE GRENADINES',
  'ST KITTS AND NEVIS': 'SAINT KITTS AND NEVIS',
  'SINT MAARTEN': 'BONAIRE, SINT EUSTATIUS AND SABA',
  'BONAIRE, SINT EUSTATIUS, AND SABA': 'BONAIRE, SINT EUSTATIUS AND SABA',
  'SAINT BARTHÉLEMY': 'SAINT BARTHLEMY',
  'U.S. OUTLYING ISLANDS': 'UNITED STATES MINOR OUTLYING ISLANDS',
  'PITCAIRN ISLANDS': 'PITCAIRN',
  'IVORY COAST': "CôTE D'IVOIRE",
  ESWATINI: 'SWAZILAND',
  'DR CONGO': 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  'CONGO REPUBLIC': 'CONGO',
  'CABO VERDE': 'CAPE VERDE',
  CZECHIA: 'CZECH REPUBLIC',
  VIETNAM: 'VIET NAM',
  'SOUTH KOREA': 'KOREA, REPUBLIC OF',
  'NORTH KOREA': "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  PALESTINE: 'PALESTINIAN TERRITORY, OCCUPIED',
  LAOS: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
}

/**
 * Resolve the given country selection
 * to a MSD compatible format
 */
export function resolveCountryValue(value, $store) {
  const country = value || 'none'
  const cData = $store.getters['locale/getCountryByCountryCode'](country, true)
  if (cData) {
    const countryName = cData.countryName.toUpperCase()
    if (countryReplacementMap[countryName]) {
      return countryReplacementMap[countryName]
    }
    return countryName
  }
  return 'UNKNOWN'
}

/**
 * Get a list of all major MSD form fields
 */
function getFormItems(form) {
  const fields = form.querySelectorAll('[data-editorblocktype^="Field"]')
  const submit = form.querySelector(
    '[data-editorblocktype="SubmitButtonBlock"] button'
  )
  const list = form.querySelector(
    '[data-editorblocktype="SubscriptionListBlock"] input'
  )
  return {
    fields,
    submit,
    list,
  }
}

/**
 * Execute the given form by clicking
 * on the given button and poll the form DOM
 * for the result string.
 */
function submitForm(form, button) {
  const tryLimit = 4
  let tryCounter = 0
  let interval
  const checkFormResults = () => {
    return form.querySelector('[data-submissionresponse]')
  }
  return new Promise((resolve) => {
    interval = setInterval(() => {
      tryCounter++

      // Check for form success
      const res = checkFormResults()
      if (res) {
        clearInterval(interval)
        const status = res.getAttribute('data-submissionresponse')
        submissionLog({
          type: 'data-submissionresponse',
          statusReponse: status,
        })
        resolve(status === 'success')
        // click reload button, so we can submit again if needed
        form.querySelector('.onFormSubmittedFeedbackButton').click()
      }

      // End polling after tryLimit tries
      if (tryCounter > tryLimit && interval) {
        clearInterval(interval)
        submissionLog({
          type: 'data-submissionresponse',
          statusReponse: 'End polling after tryLimit tries',
        })
        resolve(false)
      }
    }, 1000)
    button.click()
  })
}

/**
 * Provides an object with all methods to modify
 * and submit the main MSDynamics Form
 */
function mainFormActions(form) {
  const { fields, submit } = getFormItems(form)
  return {
    firstName: (v) => setInputValue(fields[0].querySelector('input'), v),
    lastName: (v) => setInputValue(fields[1].querySelector('input'), v),
    textField: (v) => setInputValue(fields[2].querySelector('textarea'), v),
    country: (v) => setCountryValue(fields[3], initData.main.hostname, v),
    email: (v) => setInputValue(fields[4].querySelector('input'), v),
    gender: (v) =>
      setRadioValue(
        fields[5].querySelectorAll('input'),
        v,
        ['male', 'female', 'other'],
        2
      ),
    website: (v) => setInputValue(fields[6].querySelector('input'), v),
    submit: () => submitForm(form, submit),
    contactConsent: (v) => setCheckbox(fields[10].querySelector('input'), v),
  }
}

/**
 * Provides an object with all methods to modify
 * and submit the newsletter MSDynamics Form
 */
function newsletterFormActions(form) {
  const { fields, submit, list } = getFormItems(form)
  return {
    firstName: (v) => setInputValue(fields[0].querySelector('input'), v),
    lastName: (v) => setInputValue(fields[1].querySelector('input'), v),
    email: (v) => setInputValue(fields[2].querySelector('input'), v),
    country: (v) => setCountryValue(fields[3], initData.newsletter.hostname, v),
    language: (v) => setSelectValue(fields[4].querySelector('select'), v),
    mentoringProgram: (v) => setCheckbox(list, v),
    submit: () => submitForm(form, submit),
  }
}

/**
 * Prepares the form id, used to lookup
 * already defined forms
 */
function getFormId(identifier) {
  return `msdynamics-form-embed-${identifier}`
}

/**
 * Resolves the MSD Form identifier to the
 * appropriate form action wrapper
 */
function getFormAction(identifier, form) {
  switch (identifier) {
    case 'main':
      return mainFormActions(form)
    case 'newsletter':
      return newsletterFormActions(form)
  }
  throw new Error(`MSD form with identifier ${identifier} is not defined`)
}

/**
 * Gets the given MSD form node and actions
 */
export function getFormById(identifier) {
  const form = document.getElementById(getFormId(identifier))
  if (!form) {
    return null
  }
  return {
    node: form,
    actions: getFormAction(identifier, form),
  }
}

/**
 * Generate all nodes for the
 * given MSDynamics form data
 */
function buildForm(options, tracking, identifier) {
  const formId = getFormId(identifier)

  // if form is already injected, return its instance
  const formLookup = document.getElementById(formId)
  if (formLookup) {
    return formLookup
  }

  // else build form and append to body
  const wrapper = document.createElement('div')
  wrapper.id = formId
  wrapper.style.display = 'none'
  wrapper.setAttribute('data-form-block-id', options.wrapper)
  let item

  item = document.createElement('div')
  item.id = options.element
  wrapper.append(item)

  if (tracking) {
    item = document.createElement('div')
    item.classList.add('d365-mkt-config')
    item.setAttribute('data-website-id', options.config)
    item.setAttribute('data-hostname', options.hostname)
    wrapper.append(item)
  }
  document.body.append(wrapper)
  return wrapper
}

/**
 * Checks if a script with the given src
 * has already been injected into the page
 */
function scriptExists(src) {
  return !!document.querySelector(`[src="${src}"]`)
}

/**
 * Generates a list of script nodes
 * Only generates nodes, which are not injected already
 */
function injectScripts(onLoad) {
  const scripts = []
  let item

  // Main MSD loader script
  if (!scriptExists(initData.scripts.loader)) {
    item = document.createElement('script')
    if (onLoad) {
      item.onload = () => onLoad()
    }
    item.src = initData.scripts.loader
    scripts.push(item)
  } else {
    onLoad()
  }

  // MSD tracking script
  if (!scriptExists(initData.scripts.loader)) {
    item = document.createElement('script')
    item.src = initData.scripts.tracker
    scripts.push(item)
  }
  document.head.append(...scripts)
}

export function getFormConfig(formName) {
  const form = initData[formName]
  if (!form) {
    throw new Error(`Form ${form} not defined`)
  }
  return {
    scripts: initData.scripts,
    ...form,
  }
}

/**
 * This is required since we need to hook into
 * the lifecycle of a window variable, which gets defined
 * when the injected scripts are loaded. This prevents
 * requiring polling variables
 */
export function init(onLoad, formIds) {
  const forms = {
    main: () => buildForm(getFormConfig('main'), true, 'main'),
    newsletter: () =>
      buildForm(getFormConfig('newsletter'), false, 'newsletter'),
  }

  // Init all forms given by list
  // of valid formIds
  formIds.forEach((id) => {
    if (forms[id]) {
      forms[id]()
    }
  })

  injectScripts(onLoad)
}
