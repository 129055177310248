export default {
  API: '/api',
  HELLO: '/core/hello',
  MEMBERS: '/core/members',
  FOLLOWS: '/follows',
  FORUM_POSTS: '/forums/posts',
  FORUM_TOPIC: '/forums/topic',
  FORUM_TOPICS: '/forums/topics',
  CALENDAR_EVENTS: '/calendar/events',
  NOTIFICATIONS: '/notifications',
  MESSENGER: '/messenger',
  COMPONSE: '/compose',
}
