import usergroup from '~/utils/usergroup'

export const state = () => {
  return {
    avatarUrl: '',
    firstName: '',
    lastName: '',
    email: '',
    uid: '',
    group: [usergroup.VISITOR],
    forumId: '',
  }
}

export const mutations = {
  setUser(state, { user }) {}, // todo get user from med-el service
  setAvatarUrl(state, url) {
    state.avatarUrl = url
  },
  setGroup(state, group) {
    state.group = [group]
  },
  addGroup(state, group) {
    state.group = [...state.group, group]
  },
  removeGroup(state, group) {
    state.group = state.group.filter((item) => item !== group)
  },
  setForumId(state, id) {
    state.forumId = id
  },
}

export const getters = {
  getAvatarUrl: (state) => {
    return state.avatarUrl
  },
  getName: (state) => {
    return state.name
  },
  getEmail: (state) => {
    return state.email
  },
  getGroup: (state) => {
    return state.group
  },
  getForumId: (state) => {
    return state.forumId
  },
}
