export default ({ app, store }, inject) => {
  // Inject $translation(key) in Vue, context and store.
  inject('translation', (key, placeholders) => {
    let translation = store.state.locale.translations[key]

    // if translation is not defined return the key
    // so we can see missing translations during development
    if (translation === undefined) {
      return key
    }

    // resolve placeholders
    if (placeholders) {
      for (const [key, value] of Object.entries(placeholders)) {
        translation = translation?.replaceAll('{' + key + '}', value)
      }
    }
    return translation
  })

  // Inject $datetime(date) in Vue, context and store.
  // get locale datetime format for <time> elements
  inject('datetime', (date) => {
    return app
      .$dayjs(date)
      .locale(store.app.i18n.localeProperties.iso)
      .format('YYYY-MM-DDTHH:mm:ssZ[Z]')
  })

  inject('readingTime', (text) => {
    const WORDS_PER_MINUTE = 250
    const textLength = text.split(/\s/g).length
    return Math.ceil(textLength / WORDS_PER_MINUTE)
  })

  inject('groupBy', (items, key) => {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    )
  })
}
