import getContentSlugs from '~/gql/getContentSlugs'

/**
 * Generates a list of all locales
 * for all entries in the given queryResult list
 */
function generateSlugList(queryResult, localeList) {
  const list = []
  queryResult.forEach((page) => {
    const item = {}
    // let localeCount = 0
    for (let i = 0; i < page.localizations.length; i++) {
      if (!localeList.includes(page.localizations[i].locale)) {
        continue
      }
      item[page.localizations[i].locale] = page.localizations[i].slug
      if (page.localizations[i].slug === null) {
        return
      }

      // localeCount++
    }
    list.push(item)
  })
  return list
}

/**
 * Requests the GCMS api for all content slugs and
 * returns a full slug translation map
 */
export async function buildLocaleRoutingMap($graphcms, i18n) {
  const results = await $graphcms.request(getContentSlugs)
  const mapping = {}
  Object.keys(results).forEach((type) => {
    mapping[type] = generateSlugList(results[type], i18n.localeCodes)
  })
  return mapping
}
